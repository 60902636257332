import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import type { IndexActivitySellerPayload, ActivitySeller } from "@/store/modules/activity-seller.store";
import type { IndexSellerPayload, Seller } from "@/store/modules/seller.store";

@Component
export class HasSellers extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("seller/all") sellers!: Seller[];

    @Action("activity-seller/index") indexActivitySellers!: (payload: IndexActivitySellerPayload) => Promise<ActivitySeller[]>;
    @Action("seller/index") indexSellers!: (payload: IndexSellerPayload) => Promise<Seller[]>;
    @Action("seller/clear") clearSellers!: () => Promise<void>;

    /**
     * Get the sellers for the activity.
     *
     * @param query The query to filter the sellers.
     * @param skip_mutation Whether to skip the mutation.
     * 
     * @returns The sellers for the activity.
     */
    async getSellers(query: any = { q: { and: { activity_id: this.activity.id } } }, skip_mutation = false): Promise<Seller[]> {
        const sellerIds = await this.indexActivitySellers(query);

        if (sellerIds.length) {
            return await this.indexSellers({ q: { or: { id: sellerIds.map((seller) => seller.seller_id) } }, skip_mutation });
        }

        this.clearSellers();

        return [];
    }
}
