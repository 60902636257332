
  import { Action, Getter } from "vuex-class";
  import { Component, Mixins, Ref, Watch, Prop } from "vue-property-decorator";
  import { FormBase, FormInput, FormSelect, FormCheckbox, FormTextarea, FormError, FormSubmit, FormDate, FormPhone } from "@/components/forms";
  import { formatErrors } from "@/utils/formatters";
  import { bus } from "@/main";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { IndexAccountPayload } from "@/store/modules/account.store";
  import { HasSellers } from "@/mixins/has-sellers";
  import { format } from "fecha";
  import { PartnerRequest } from "@/store/modules/partner-request.store";
  import { TeamFunctions } from "@/store/modules/activity-account.store";
  import { contactTypeOptions, SelectOption } from "@/utils/select-options";

  // Types
  import { ContactType, CreateActivitySellerPayload } from "@/store/modules/activity-seller.store";
  import type { CalendarAction } from "@/store/modules/appointment.store";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      AppointmentLocationPart: () => import("@/components/modals/parts/appointment-location.vue"),
      FormBase,
      FormDate,
      FormError,
      FormCheckbox,
      FormSelect,
      FormPhone,
      FormInput,
      FormTextarea,
      FormSubmit,
    },
  })
  export default class CreateAppointmentModal extends Mixins(HasFormErrors, HasSellers) {
    @Getter("auth/me") account!: CurrentAccount;
    @Getter("property/viewing") property!: Property;
    @Getter("appointment/actions") actions!: CalendarAction[];
    @Getter("account/all") accounts!: Account[];

    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;
    @Action("appointment/create") createAppointment!: (payload: AppointmentCreatePayload) => Promise<Appointment>;
    @Action("appointment/actions") getActions!: () => Promise<CalendarAction[]>;

    @Prop({ required: false, default: null }) partnerRequest!: PartnerRequest | null;

    @Ref() form!: FormClass;

    @Ref() panel!: HTMLElement;

    @Ref() emailInput!: HTMLElement;

    @Ref() contactInput!: HTMLElement;

    @Ref() appointmentLocation!: any;

    payload: AppointmentCreatePayload = {
      planned_at: "",
      duration: 30,
      contacts: [],
      guests: [],
      title: "",
      description: "",
      activity_id: NaN,
      action_id: null,
      invited_by: null,
      location: {
        street: "",
        number: "",
        additional: "",
        city: "",
        zip: "",
      },
    };

    contact = {
      type: ContactType.owner as ContactType | null | undefined,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      no_email: false,
    };

    show = false;

    showAdd = false;

    editableDescription = "";

    nonEditableDescription = "";

    type: "update" | "create" | null = "create";

    contacts: Array<{ first_name: string; last_name: string; email: string }> = [];

    /**
     * The Id of the current inviter, usually this is the signed in user (Auth)
     *
     */
    get currentInviterId(): number | null {
      if (this.organiserOptions.length === 0) {
        return null;
      }

      return this.payload.invited_by;
    }

    set currentInviterId(value: number | null) {
      this.payload.invited_by = value;
    }

    /**
     * The Id's of allthe invited members, this is a list of linked sellers and employees.
     *
     */
    get currentMembers(): number[] {
      if (this.organiserOptions.length === 0) {
        return [];
      }

      return this.payload.guests;
    }

    set currentMembers(value: number[]) {
      this.payload.guests = value;
    }

    /**
     * A list of all the actions that can be selected in the form.
     * A partner only has access to his specific actions. (Foto's, Keuring, ...)
     *
     */
    get actionOptions(): Array<{ value: number; label: string; duration: number | null }> {
      if (!this.actions) {
        return [];
      }

      return this.actions.map((a) => {
        return {
          value: a.id,
          label: a.name,
          duration: a.duration,
        };
      });
    }

    /**
     * A list of all the partner accounts that can be selected in the form.
     *
     */
    get extraOrganiser() {
      if (this.partnerRequest === null) {
        return [];
      }

      return this.partnerRequest.activity.assigned_to ?? [];
    }

    /**
     * A list of all the accounts that can be selected in the form.
     * Accounts with no Whise Id are removed from the list
     *
     */
    get organiserOptions() {
      return [...this.accounts, ...this.extraOrganiser]
        .filter((a) => !!a.whise_id)
        .map((a) => {
          return {
            value: a.id,
            whise_id: a.whise_id,
            label: `${a.first_name} ${a.last_name}`,
            user: {
              first_name: a.first_name,
              last_name: a.last_name,
              media: a.media,
            },
          };
        });
    }

    /**
     * A list of all the partner accounts that can be selected in the form.
     *
     */
    get extraEmployees() {
      if (this.partnerRequest === null) {
        return [];
      }

      return this.partnerRequest.activity.assigned_to?.filter((f) => f.type === TeamFunctions.backoffice) ?? [];
    }

    /**
     * A list of all the guest accounts that can be selected in the form.
     *
     */
    get otherEmployeesOptions() {
      return [...this.accounts, ...this.extraEmployees]
        .filter((a) => !!a.whise_id)
        .map((a) => {
          return {
            value: a.id,
            whise_id: a.whise_id,
            label: `${a.first_name} ${a.last_name}`,
            user: {
              first_name: a.first_name,
              last_name: a.last_name,
              media: a.media,
            },
          };
        });
    }

    /**
     * A list of all the partner accounts that can be selected in the form.
     *
     */
    get extraContacts() {
      if (this.partnerRequest === null) {
        return [];
      }

      return this.partnerRequest.activity.contacts ?? [];
    }

    /**
     * A list of all the contacts that can be selected in the form.
     *
     */
    get contactOptions() {
      return [...this.sellers, ...this.extraContacts]
        .filter((s) => !this.payload.contacts.map((c) => c.email).includes(s.email ?? ""))
        .map((a) => {
          return {
            value: a.id,
            label: `${a.first_name} ${a.last_name}`,
            user: {
              first_name: a.first_name,
              last_name: a.last_name,
              email: a.email,
              phone: a.phone,
              no_email: false,
            },
          };
        });
    }

    /*
     * A list of all the duration options that can be selected in the form.
     *
     */
    get durationOptions() {
      return [
        {
          value: 5,
          label: "5 minuten",
        },
        {
          value: 15,
          label: "15 minuten",
        },
        {
          value: 20,
          label: "20 minuten",
        },
        {
          value: 30,
          label: "30 minuten",
        },
        {
          value: 45,
          label: "45 minuten",
        },
        {
          value: 60,
          label: "1 uur",
        },
        {
          value: 90,
          label: "1,5 uur",
        },
        {
          value: 120,
          label: "2 uur",
        },
      ];
    }

    /**
     * A list of all the contact types that can be selected in the form.
     *
     */
    get filteredContactTypes() {
      return contactTypeOptions.filter((ct: SelectOption) => ([ContactType.owner, ContactType.contact, ContactType.buyer, ContactType.potentialBuyer] as string[]).includes(ct.value as string));
    }

    async mounted() {
      if (this.actions.length === 0) {
        await this.getActions();
      }

      this.initOpenClose();
    }

    initOpenClose() {
      bus.$off("show-create-appointment");
      bus.$on("show-create-appointment", async () => {
        this.loadAdminMetaData();

        this.loadPartnerMetaData();

        this.show = true;
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.panel.scrollLeft = 0;
          });
        });

        this.payload.action_id = this.actionOptions.find((a) => a.label === "Schatting")?.value || this.actions[0]?.id || null;
        this.payload.planned_at = format(new Date(), "YYYY-MM-DD[T]HH:mm:ssZ");
        this.payload.duration = 60;

        this.maybeChangeDescription();

        this.preselectOfficeOrAddress();
      });
    }

    async submit(form: FormClass) {
      try {
        this.payload.activity_id = this.activity.id;

        this.payload.description = "";
        if (this.editableDescription) {
          this.payload.description += this.editableDescription;
          this.payload.description += "\n\n";
        }

        this.payload.description += this.nonEditableDescription;

        //@ts-ignore
        await this.createAppointment(this.payload);

        this.$toast.open({ message: this.$t("views.appointments.create.success") as string, type: "success", position: "bottom-right" });

        this.handleClose();
      } catch (e) {
        this.errorResponse = formatErrors(e);
      } finally {
        form.reset();
      }
    }

    /**
     * Load admin speficic data such as accounts and seller accounts
     * Partner accounts do not have access to this data.
     */
    loadAdminMetaData() {
      if (this.partnerRequest !== null) {
        return;
      }

      this.getSellers();

      if (!this.accounts.length) {
        this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, per_page: 99 });
      }

      if (this.type === "create") {
        this.payload.invited_by = Number(this.account.id);
      }
    }

    /**
     * Load partner speficic data such as initial guests, ...
     * Admin accounts do not have access to this data.
     */
    loadPartnerMetaData() {
      if (this.partnerRequest === null) {
        return;
      }

      if (this.type === "create") {
        this.payload.guests = this.otherEmployeesOptions.map((p) => Number(p.value));
        this.payload.invited_by = this.extraOrganiser.find((f) => f.type === TeamFunctions.buyer)?.id ?? null;
        // @ts-ignore
        this.payload.contacts = [...this.extraContacts];
      }

      this.payload.office_id = this.partnerRequest.activity.office.id;
    }

    handleContactAdded(contact: { type?: ContactType | null; first_name: string; last_name: string; email: string | null; phone: string | null; no_email: boolean }) {
      if (contact.email && !contact.no_email && !this.payload.contacts.find((c) => c.email === contact.email)) {
        //@ts-ignore
        this.payload.contacts.push(contact);
      } else if (contact.no_email || contact.email === null) {
        this.payload.contacts.push({
          type: contact.type,
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: null,
          phone: contact.phone || null,
        });
      } else if (contact.email) {
        this.contact = {
          type: contact.type,
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: contact.email,
          phone: contact.phone || "",
          no_email: false,
        };
        this.showAdd = true;

        // @ts-ignore
        if (this.contactInput) {
          // @ts-ignore
          this.contactInput.input.open = false;
        }
        // @ts-ignore
        this.emailInput.$el.querySelector("input").focus();
      }

      this.maybeChangeDescription();

      this.contacts = [];
    }

    editContact() {
      this.showAdd = true;
    }

    editAppointment() {
      this.showAdd = false;
    }

    setTitle(title: string) {
      if (this.type === "update") {
        return;
      }
      this.payload.title = title;
    }

    maybeChangeDescription() {
      const label = this.actionOptions.find((a) => a.value === this.payload.action_id)?.label;
      if (!label) {
        return;
      }
      const contactName = this.payload.contacts.map((c) => `${c.first_name} ${c.last_name}`).join(" of ");
      const contactNumbers = this.payload.contacts.map((c) => `${c.phone ?? ""}`).join(" of ");
      const activityName = this.activity.name;

      this.nonEditableDescription = "";

      this.setTitle(`${label} - ${activityName}`);
      switch (label) {
        case "Bezoek":
          this.setTitle(`Bezichtigen van het eigendom: ${activityName}`);
          this.nonEditableDescription = `*Kandidaat*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Compromis":
          this.setTitle(`Afspraak om de verkoopovereenkomst te tekenen van het eigendom: ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Schatting":
          this.setTitle(`Schatting van het eigendom: ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Telefoon":
          if (contactName && contactNumbers) {
            this.nonEditableDescription = `Bel ${contactName} op via het nummer ${contactNumbers} ivm het dossier ${activityName}.`;
          }
          break;
        case "Vergadering":
          this.setTitle(`Vergadering ivm ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Opmeting":
          break;
        case "Kantoor dienst":
          this.nonEditableDescription = "Permanentie op kantoor.";
          break;
        case "Vakantie":
          this.nonEditableDescription = "Ingepland verlof.";
          break;
        case "Prospectie":
          this.nonEditableDescription = "Prospecteren in jouw regio.";
          break;
        case "Taxatieverslag":
          this.setTitle(`Taxatie van het eigendom: ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Bespreking schatting":
          this.setTitle(`Bespreking schatting -  ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Akte":
          this.setTitle(`Akte verlijden -  ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Keuring":
          this.setTitle(`Uitvoering keuring van het dossier: ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          this.nonEditableDescription = `Type keuring: `;
          break;
        case "Foto's":
          this.setTitle(`Foto's maken -  ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Andere":
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
        case "Keuring sleutel kantoor":
          this.setTitle(`Uitvoering keuring met sleutel op kantoor van het dossier: ${activityName}`);
          this.nonEditableDescription = `Type keuring: `;
          break;
        case "Huurovereenkomst":
          this.setTitle(`Uitvoering keuring met sleutel op kantoor van het dossier: ${activityName}`);
          this.nonEditableDescription = `*Contactpersoon*`;
          this.nonEditableDescription += this.generateContactString();
          this.nonEditableDescription += this.generateRealtorString(Number(this.payload.invited_by));
          this.payload.guests.forEach((guest_id: number) => {
            this.nonEditableDescription += this.generateRealtorString(Number(guest_id));
          });
          break;
      }
    }

    addContact(form: FormClass) {
      this.handleContactAdded(this.contact);
      this.contact = {
        type: ContactType.owner,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        no_email: false,
      };
      this.showAdd = false;
      form.reset();
    }

    removeContact(index: number) {
      this.payload.contacts.splice(index, 1);
    }

    handleClose() {
      this.errorResponse = {
        status: 0,
        errors: [],
      };

      this.payload = {
        planned_at: "",
        duration: null,
        contacts: [],
        guests: [],
        title: "",
        description: "",
        activity_id: NaN,
        action_id: null,
        invited_by: null,
        location: {
          street: "",
          number: "",
          additional: "",
          zip: "",
          city: "",
        },
      };

      this.show = false;
    }

    generateContactString() {
      let text = "";

      if (!this.payload.contacts.length) {
        return "\n-";
      }

      this.payload.contacts.forEach((c) => {
        text += `\nNaam: ${c.first_name} ${c.last_name}`;
        if (c.phone) {
          text += `\nGSM: ${c.phone}`;
        }
        if (c.email) {
          text += `\nE-mail: ${c.email}`;
        }
        text += `\n`;
      });

      return text;
    }

    generateRealtorString(account_id: number) {
      const account = this.accounts.find((a) => Number(a.id) === account_id);

      let text = "";

      if (!account) {
        return "";
      }

      if (account.function_title) {
        text += "\n\n*" + this.$t(`views.account.functions.${account.function_title}`) + "*";
      } else {
        text += `\n\n*Makelaar*`;
      }
      text += `\nNaam: ${account.first_name} ${account.last_name}`;
      if (account.phone) {
        text += `\nGSM: ${account.phone}`;
      }
      if (account.email) {
        text += `\nE-mail: ${account.email}`;
      }
      text += `\n`;

      return text;
    }

    actionChanged() {
      this.maybeChangeDescription();

      this.preselectOfficeOrAddress();
    }

    preselectOfficeOrAddress() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            // @ts-ignore
            if (this.appointmentLocation) {
              const label = this.actionOptions.find((a) => a.value === this.payload.action_id)?.label;
              if (!label) {
                return;
              }

              if (label === "Schatting" || label === "Bezoek" || this.partnerRequest !== null) {
                // @ts-ignore
                this.appointmentLocation.selectedOfficeOrAddress = "property_address";
                // @ts-ignore
                this.appointmentLocation.handleOfficeOrOtherChanged();
              }
              if (label === "Compromis") {
                this.appointmentLocation.selectedOfficeOrAddress = `office_${this.activity.office_id}`;
                // @ts-ignore
                this.appointmentLocation.handleOfficeOrOtherChanged();
              }
            }
          }, 500);
        });
      });
    }

    @Watch("payload.action_id")
    handleActionIdChanged(newValue: number) {
      const action = this.actionOptions.find((a) => a.value === newValue);
      if (action && action.duration) {
        this.payload.duration = action.duration;
      }
    }
  }
