import Vue from "vue";
import { i18n } from "@/localization";
import { ContactType } from "@/store/modules/activity-seller.store";
import { AsbestosType, EkType, EpcType, FueltankType } from "@/store/modules/partner.store";

export interface SelectOption {
    label: string;
    value: string | number;
}

const maritalStateOptions: SelectOption[] = [
    { label: "Ongehuwd", value: "unmarried" },
    { label: "Feitelijk samenwonend", value: "living_together" },
    { label: "Wettelijk samenwonend", value: "leagally_living_together" },
    { label: "Gehuwd", value: "married" },
    { label: "Gescheiden", value: "separated" },
    { label: "Weduwnaar/weduwe", value: "widower" },
];

const titleOptions: SelectOption[] = [
    { label: i18n.t("representative.enums.title.mr") as string, value: "mr" },
    { label: i18n.t("representative.enums.title.mrs") as string, value: "mrs" },
];

const companyTypes: SelectOption[] = [
    { value: "Eenmanszaak", label: "Eenmanszaak" },
    { value: "Besloten Vennootschap (BV)", label: "Besloten Vennootschap (BV)" },
    { value: "Naamloze Vennootschap (NV)", label: "Naamloze Vennootschap (NV)" },
    { value: "Coöperatieve Vennootschap (CV)", label: "Coöperatieve Vennootschap (CV)" },
    { value: "Commanditaire Vennootschap (Comm.v)", label: "Commanditaire Vennootschap (Comm.v)" },
    { value: "Vennootschap onder Firma (Vof)", label: "Vennootschap onder Firma (Vof)" },
    { value: "Vereniging zonder Winstoogmerk (Vzw)", label: "Vereniging zonder Winstoogmerk (Vzw)" },
    { value: "Internationele Vereniging zonder Winstoogmerk (Ivzw)", label: "Internationele Vereniging zonder Winstoogmerk (Ivzw)" },
    { value: "Private stichting", label: "Private stichting" },
    { value: "Stichting van openbaar nut", label: "Stichting van openbaar nut" },
];

const countryOptions: SelectOption[] = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albanië" },
    { value: "DZ", label: "Algerije" },
    { value: "AS", label: "Amerikaans-Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AG", label: "Antigua en Barbuda" },
    { value: "AR", label: "Argentinië" },
    { value: "AM", label: "Armenië" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australië" },
    { value: "AT", label: "Oostenrijk" },
    { value: "AZ", label: "Azerbeidzjan" },
    { value: "BS", label: "Bahama’s" },
    { value: "BH", label: "Bahrein" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "België" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BQ", label: "Caribisch Nederland" },
    { value: "BA", label: "Bosnië en Herzegovina" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouveteiland" },
    { value: "BR", label: "Brazilië" },
    { value: "IO", label: "Brits Indische Oceaanterritorium" },
    { value: "BN", label: "Brunei" },
    { value: "BG", label: "Bulgarije" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Cambodja" },
    { value: "CM", label: "Kameroen" },
    { value: "CA", label: "Canada" },
    { value: "CV", label: "Kaapverdië" },
    { value: "KY", label: "Kaaimaneilanden" },
    { value: "CF", label: "Centraal-Afrikaanse Republiek" },
    { value: "TD", label: "Tsjaad" },
    { value: "CL", label: "Chili" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmaseiland" },
    { value: "CC", label: "Cocoseilanden" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoren" },
    { value: "CG", label: "Congo-Brazzaville" },
    { value: "CD", label: "Congo-Kinshasa" },
    { value: "CK", label: "Cookeilanden" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Ivoorkust" },
    { value: "HR", label: "Kroatië" },
    { value: "CU", label: "Cuba" },
    { value: "CW", label: "Curaçao" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Tsjechië" },
    { value: "DK", label: "Denemarken" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominicaanse Republiek" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypte" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatoriaal-Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estland" },
    { value: "ET", label: "Ethiopië" },
    { value: "FK", label: "Falklandeilanden (Islas Malvinas)" },
    { value: "FO", label: "Faeröer" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "Frankrijk" },
    { value: "GF", label: "Frans-Guyana" },
    { value: "PF", label: "Frans-Polynesië" },
    { value: "TF", label: "Franse Gebieden in de zuidelijke Indische Oceaan" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgië" },
    { value: "DE", label: "Duitsland" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Griekenland" },
    { value: "GL", label: "Groenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GN", label: "Guinee" },
    { value: "GW", label: "Guinee-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haïti" },
    { value: "HM", label: "Heard en McDonaldeilanden" },
    { value: "VA", label: "Vaticaanstad" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hongkong" },
    { value: "HU", label: "Hongarije" },
    { value: "IS", label: "IJsland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesië" },
    { value: "IR", label: "Iran" },
    { value: "IQ", label: "Irak" },
    { value: "IE", label: "Ierland" },
    { value: "IM", label: "Isle of Man" },
    { value: "IL", label: "Israël" },
    { value: "IT", label: "Italië" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JE", label: "Jersey" },
    { value: "JO", label: "Jordanië" },
    { value: "KZ", label: "Kazachstan" },
    { value: "KE", label: "Kenia" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Noord-Korea" },
    { value: "KR", label: "Zuid-Korea" },
    { value: "XK", label: "Kosovo" },
    { value: "KW", label: "Koeweit" },
    { value: "KG", label: "Kirgizië" },
    { value: "LA", label: "Laos" },
    { value: "LV", label: "Letland" },
    { value: "LB", label: "Libanon" },
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libië" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Litouwen" },
    { value: "LU", label: "Luxemburg" },
    { value: "MO", label: "Macau" },
    { value: "MK", label: "Noord-Macedonië" },
    { value: "MG", label: "Madagaskar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Maleisië" },
    { value: "MV", label: "Maldiven" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshalleilanden" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritanië" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "FM", label: "Micronesia" },
    { value: "MD", label: "Moldavië" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolië" },
    { value: "ME", label: "Montenegro" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Marokko" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar (Birma)" },
    { value: "NA", label: "Namibië" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Nederland" },
    { value: "AN", label: "Curaçao" },
    { value: "NC", label: "Nieuw-Caledonië" },
    { value: "NZ", label: "Nieuw-Zeeland" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk" },
    { value: "MP", label: "Noordelijke Marianen" },
    { value: "NO", label: "Noorwegen" },
    { value: "OM", label: "Oman" },
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PS", label: "Palestina" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papoea-Nieuw-Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Filipijnen" },
    { value: "PN", label: "Pitcairneilanden" },
    { value: "PL", label: "Polen" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" },
    { value: "RE", label: "Réunion" },
    { value: "RO", label: "Roemenië" },
    { value: "RU", label: "Rusland" },
    { value: "RW", label: "Rwanda" },
    { value: "BL", label: "Saint-Barthélemy" },
    { value: "SH", label: "Sint-Helena" },
    { value: "KN", label: "Saint Kitts en Nevis" },
    { value: "LC", label: "Saint Lucia" },
    { value: "MF", label: "Saint-Martin" },
    { value: "PM", label: "Saint-Pierre en Miquelon" },
    { value: "VC", label: "Saint Vincent en de Grenadines" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tomé en Principe" },
    { value: "SA", label: "Saoedi-Arabië" },
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Servië" },
    { value: "CS", label: "Servië" },
    { value: "SC", label: "Seychellen" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SX", label: "Sint-Maarten" },
    { value: "SK", label: "Slowakije" },
    { value: "SI", label: "Slovenië" },
    { value: "SB", label: "Salomonseilanden" },
    { value: "SO", label: "Somalië" },
    { value: "ZA", label: "Zuid-Afrika" },
    { value: "GS", label: "Zuid-Georgia en Zuidelijke Sandwicheilanden" },
    { value: "SS", label: "Zuid-Soedan" },
    { value: "ES", label: "Spanje" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Soedan" },
    { value: "SR", label: "Suriname" },
    { value: "SJ", label: "Spitsbergen en Jan Mayen" },
    { value: "SZ", label: "Eswatini" },
    { value: "SE", label: "Zweden" },
    { value: "CH", label: "Zwitserland" },
    { value: "SY", label: "Syrië" },
    { value: "TW", label: "Taiwan" },
    { value: "TJ", label: "Tadzjikistan" },
    { value: "TZ", label: "Tanzania" },
    { value: "TH", label: "Thailand" },
    { value: "TL", label: "Oost-Timor" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad en Tobago" },
    { value: "TN", label: "Tunesië" },
    { value: "TR", label: "Turkije" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks- en Caicoseilanden" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Oeganda" },
    { value: "UA", label: "Oekraïne" },
    { value: "AE", label: "Verenigde Arabische Emiraten" },
    { value: "GB", label: "Verenigd Koninkrijk" },
    { value: "US", label: "Verenigde Staten" },
    { value: "UM", label: "Kleine afgelegen eilanden van de Verenigde Staten" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Oezbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Vietnam" },
    { value: "VG", label: "Britse Maagdeneilanden" },
    { value: "VI", label: "Amerikaanse Maagdeneilanden" },
    { value: "WF", label: "Wallis en Futuna" },
    { value: "EH", label: "Westelijke Sahara" },
    { value: "YE", label: "Jemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
];

const suspensiveConditionOptions: SelectOption[] = [
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
];

const isoCountriesCodes: Array<{ name: string; iso2: string; dialCode: string }> = [
    { name: "België", iso2: "BE", dialCode: "32" },
    { name: "Nederland", iso2: "NL", dialCode: "31" },
    { name: "Frankrijk", iso2: "FR", dialCode: "33" },
    { name: "Duitsland", iso2: "DE", dialCode: "49" },
];

const contactTypeOptions: SelectOption[] = Object.values(ContactType).map((ct: ContactType) => {
    return { value: ct, label: i18n.t(`views.contact_types.${ct.toUpperCase()}`) as string };
});

const epcTypeOptions: SelectOption[] = Object.values(EpcType).map((t: EpcType) => {
    return { value: t, label: i18n.t(`views.epc_types.${t.toLowerCase()}`) as string };
});

const ekTypeOptions: SelectOption[] = Object.values(EkType).map((t: EkType) => {
    return { value: t, label: i18n.t(`views.ek_types.${t.toLowerCase()}`) as string };
});

const asbestosTypeOptions: SelectOption[] = Object.values(AsbestosType).map((t: AsbestosType) => {
    return { value: t, label: i18n.t(`views.asbestos_types.${t.toLowerCase()}`) as string };
});

const fueltankTypeOptions: SelectOption[] = Object.values(FueltankType).map((t: FueltankType) => {
    return { value: t, label: i18n.t(`views.fueltank_types.${t.toLowerCase()}`) as string };
});

export { maritalStateOptions, titleOptions, countryOptions, companyTypes, suspensiveConditionOptions, isoCountriesCodes, contactTypeOptions, epcTypeOptions, ekTypeOptions, asbestosTypeOptions, fueltankTypeOptions };
